import React from 'react';
import { graphql } from 'gatsby';

const ClientSideOnlyLazy = React.lazy(() =>
  import('../components/Page/TemplatesLibrary/TemplatesLibraryWrapper'),
);

const TemplatesLibrary = ({ data }) => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense
          fallback={<div style={{ height: '100vh', width: '100vw', background: 'white' }} />}
        >
          <ClientSideOnlyLazy data={data} />
        </React.Suspense>
      )}
    </>
  );
};

export default TemplatesLibrary;

export const TemplatesLibraryPageQuery = graphql`
  query TemplatesLibraryPageQuery {
    allContentfulTemplateLibraryCard {
      edges {
        node {
          ...TemplateLibraryCard
        }
      }
    }
  }
`;
